@import "../../node_modules/bootstrap/scss/mixins";

.ftco-footer {
  font-size: 14px;
  color: rgba($white, 0.7);

  a {
    color: rgba($white, 0.7);
    &:hover {
      color: $primary;
    }
  }

  img {
    width: 180px;
    margin: 0;
    box-shadow: none;
    border-radius: 3px;
    @include media-breakpoint-down(md) {
      width: 140px;
    }
  }

  .tas-logo {
    width: 120px;
    margin: 0;
    box-shadow: none;
    border-radius: 3px;
    @include media-breakpoint-down(md) {
      width: 100px;
    }
  }

  #partners,
  .ftco-footer-widget {
    z-index: 1;
  }

  .ftco-footer-social {
    margin-top: 2rem;
    li {
      list-style: none;
      margin: 0 10px 0 0;
      display: inline-block;
      a {
        height: 50px;
        width: 50px;
        display: block;
        float: left;
        background: rgba($white, 0.05);
        border-radius: 50%;
        position: relative;
        span {
          position: absolute;
          font-size: 26px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .ftco-footer-contact {
    .contact-list {
      padding: 0;
      margin-bottom: 2rem;

      .contact-list-item {
        z-index: 1;
        margin-bottom: 1rem;

        &,
        > a {
          // display: table;
          line-height: 1.5;
        }
        span {
          color: rgba($white, 0.7);
        }

        .fa {
          margin-right: 15px;
        }
      }
    }
  }

  .partner-logo {
    margin: 15px;
    z-index: 1;
    @include media-breakpoint-down(md) {
      margin: 10px;
    }
    img {
      width: 130px;
      margin: 0;
      box-shadow: none;
      border-radius: 3px;
      @include media-breakpoint-down(md) {
        width: 120px;
      }
    }
  }
}
