// ======= CUSTOM BOOTSTRAP =======
// Custom colors
// $primary: #cf112f;
$primary: #cb2228;
$secondary: #959e31;
$light: #f8f9fa;
$white: #fff;
$black: #000000;
$lightgray: #494948;
$darkgray: #2a2a2a;
$blackberry: #25004a;
$blackberry2: #402876;
$blueberry: #2c3b73;
$blueberry2: #2b4f81;
$raspberry: #bb2440;

// Import the functions and variables
@import "../../node_modules/bootstrap/scss/_functions";
@import "../../node_modules/bootstrap/scss/_variables";

// Add custom colors to Bootstrap
$custom-theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "lightgray": $lightgray,
  "darkgray": $darkgray,
  "blackberry": $blackberry,
  "blackberry2": $blackberry2,
  "blueberry": $blueberry,
  "blueberry2": $blueberry2,
  "raspberry": $raspberry,
);

$theme-colors: map-merge($custom-theme-colors, $theme-colors);

// Import Bootstrap, Animate
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/animate.css/animate.css";

// Import scss files
@import "navbar";
@import "banner";
@import "body-sections";
@import "products";
@import "sites";
@import "gallery";
@import "footer";
@import "utilities";

// Import Google fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// $font-primary: 'Gotham', sans-serif;
$font-secondary: "Satisfy", cursive;
$font-primary: "Montserrat", sans-serif;
$font-nabla: "Nabla", cursive;

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin transition($transition) {
  -moz-transition: all $transition ease;
  -o-transition: all $transition ease;
  -webkit-transition: all $transition ease;
  -ms-transition: all $transition ease;
  transition: all $transition ease;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  font-family: $font-primary;
  background: $white;
  font-size: 1rem;
  // letter-spacing: 1px;
  line-height: 1.75;
  font-weight: 300;
  text-align: left;
  color: lighten($black, 50%);
  overflow: hidden; /* Hide horizontal scrollbar */

  &.menu-show {
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
  }
}

a {
  transition: 0.3s all ease;
  text-decoration: none;

  &:hover,
  &:focus,
  &:visited,
  &:active {
    // color: $primary;
    text-decoration: none;
    outline: none !important;
  }
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.5;
  color: inherit;
}

h1 {
  @include media-breakpoint-up(lg) {
    font-size: 45px;
  }

  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.5px;
}

h2 {
  font-weight: 700;
  letter-spacing: 1.2px;
}

h3 {
  font-weight: 500;
}

// ================================================================
.typing-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.typing-container p {
  font-size: 3rem;
  padding: 0.5rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-align: center;
  overflow: hidden;
}
.typing-container p span.typed-text {
  font-weight: normal;
  // color: #ffffff;
}
.typing-container p span.cursor {
  display: inline-block;
  background-color: rgba($primary, 0.8);
  margin-left: 00.5rem;
  width: 5px;
  animation: blink 1s infinite;
}
.typing-container p span.cursor.typing {
  animation: none;
}
@keyframes blink {
  0% {
    background-color: rgba($primary, 0.8);
  }
  49% {
    background-color: rgba($primary, 0.8);
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
  100% {
    background-color: rgba($primary, 0.8);
  }
}
