@import "../../node_modules/bootstrap/scss/mixins";

.ftco-banner {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  background-image: url("../../public/images/head-image-overlay-120.jpg");
  @include media-breakpoint-down(sm) {
    background-image: url("../../public/images/head-image-overlay-120.jpg");
  }
  &.banner-404 {
    background-image: url("../../public/images/head-image-overlay-120.jpg");
    background-position: top center;
  }

  .banner-text {
    transform: translateY(-20%);
    @include media-breakpoint-down(md) {
      transform: translateY(-35%);
    }
    @include media-breakpoint-down(sm) {
      transform: translateY(-50%);
    }

    p {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.5;
      color: rgba($white, 0.7);
      @include media-breakpoint-down(lg) {
        font-size: 18px;
      }
      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
  }
}
