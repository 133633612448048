@import "../../node_modules/bootstrap/scss/mixins";

.gallery {
  display: block;
  width: 100%;
  height: 400px;
  margin-bottom: 0;
  background-position: center center !important;
  z-index: 0;
  position: relative;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background: $black;
    opacity: 0.4;
    z-index: 0;
    transition: 0.3s all ease-in-out;
    @include media-breakpoint-down(lg) {
      opacity: 0;
    }
  }

  transition: 0.3s all ease-in-out;
  @include media-breakpoint-up(lg) {
    // filter: grayscale(1);
    &:hover {
      transform: scale(1.03);
      // filter: grayscale(0);
      z-index: 999;
    }
  }

  &:hover {
    &:after {
      display: none;
    }
  }

  .text {
    span {
      font-size: 13px;
      color: $white;
    }
  }

  h2 {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.2;
    a {
      color: $white;
    }
  }
}

.img {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
