@import "../../node_modules/bootstrap/scss/mixins";

// SITES
.site-block {
  overflow: hidden;
  @include media-breakpoint-up(md) {
    margin-bottom: 30px;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
  }
  .text {
    position: relative;
    background: $white;
    width: 100%;
    margin: 0 auto;
    .topper {
      margin-top: -61px;
      position: absolute;
      top: 0;
      left: 20px;
      background: $primary;
      &:after {
        position: absolute;
        bottom: -10px;
        left: 40px;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: $primary transparent transparent transparent;
      }
    }
    .heading {
      font-size: 18px;
      margin-bottom: 16px;
      font-weight: 500;
      a {
        color: $black;
        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
      }
    }
  }
  span.site-name {
    font-size: 24px;
    font-weight: 400;
    color: $white;
    @include media-breakpoint-down(md) {
      font-size: 20px;
    }
  }
  p.site-address {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.2;
    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }
}
