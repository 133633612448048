@import "../../node_modules/bootstrap/scss/mixins";

.ftco-navbar {
  transition: all 0.3s ease-in;
  z-index: 9999;
  padding: 0px;
  @include media-breakpoint-down(lg) {
    position: relative;
    top: 0;
    padding: 10px 15px;
  }

  .navbar-brand {
    img {
      max-height: 80px;
      // @include media-breakpoint-down(xl) {
      //   max-height: 70px;
      // }
      // @include media-breakpoint-down(lg) {
      //   max-height: 60px;
      // }
    }
  }

  .navbar-nav {
    @include media-breakpoint-down(lg) {
      padding-top: 10px;
    }
    > .nav-item {
      > .nav-link {
        color: rgba($white, 0.56);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2px;
        position: relative;

        &:before {
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          display: block;
          width: 0;
          height: 2px;
          background: $primary;
          @include transition(0.3s);
        }

        &:hover {
          color: $primary;
          &:before {
            width: 100%;
          }
        }
      }

      &.active {
        > a {
          color: $primary;
        }
      }
    }
  }

  .navbar-toggler {
    border: none;
    font-size: 20px;
    color: rgba($white, 0.56);
    cursor: pointer;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.ftco-navbar {
  &.small {
    .navbar-brand {
      img {
        max-height: 70px;
      }
    }
    .nav-link {
      font-size: 11px !important;
    }

    .work-with-us-btn {
      font-size: 14px !important;
    }
  }
}
