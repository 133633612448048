@import "../../node_modules/bootstrap/scss/mixins";
.ftco-section,
.ftco-section-dark {
  padding: 6em 0;
  position: relative;
  @include media-breakpoint-down(md) {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  img {
    width: 100%;
    border-radius: 3px;
    margin-bottom: 18px;
    box-shadow: 0px 10px 30px -4px rgba(0, 0, 0, 0.15);
    z-index: 999;
  }

  .img-corner-right,
  .img-corner-left,
  .img-corner-bottom-right,
  .img-corner-bottom-left {
    filter: opacity(0.03);
    position: absolute;
    z-index: 0;
    width: 50%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  .img-corner-right {
    top: 0;
    right: 0;
  }
  .img-corner-left {
    top: 0;
    left: 0;
  }
  .img-corner-bottom-right {
    bottom: 0;
    right: 0;
  }
  .img-corner-bottom-left {
    bottom: 0;
    left: 0;
  }
}

.ftco-section {
  h2,
  h3 {
    color: $primary;
  }
}

.ftco-section-dark {
  color: rgba($white, 0.9);

  h2,
  h3 {
    color: $white;
  }
}
