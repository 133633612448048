@import "../../node_modules/bootstrap/scss/mixins";

// PRODUCT
.product {
  z-index: 1;
}

// WORK WITH US
#work-with-us {
  .img {
    background-image: url("../../public/images/work-with-us-1.jpg");
    @include media-breakpoint-down(lg) {
      height: 600px;
    }
  }
}

// POSITION BUTTONS
.buttons-wrap {
  position: relative;
  z-index: 0;
  width: 100%;
  .button {
    font-size: 16px;
    font-weight: 500;
    background: $white;
    color: $black;
    text-transform: uppercase;
    position: relative;
    display: block;

    &:hover {
      background: $blueberry;
      color: $white;
    }

    &:focus,
    &:active {
      background: $white;
      color: $black;
    }
  }
}

// ENQUIRY FORM
.contactForm {
  .label {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    margin-top: 20px;
  }
  .form-control {
    border: none;
    padding: 10px 10px;
  }
}

.form-group {
  margin-top: 25px;
  @include media-breakpoint-down(lg) {
    margin-top: 20px;
  }
}

.input-group-text {
  font-size: 0.8rem;
  display: inline-block;
  width: 75px;
  &.short {
    width: 75px;
  }
}

.form-control {
  height: 40px !important;
  background: $white !important;
  color: $black !important;
  font-size: 1rem;
  border-radius: 5px;
  box-shadow: none !important;
  &:focus,
  &:active {
    border-color: $black;
  }
}

textarea.form-control {
  height: inherit !important;
}

// REVEAL ANIMATION
.reveal {
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: 1s all ease;
  &.active {
    transform: translateY(0);
    opacity: 1;
  }
}

// GO TO TOP BUTTON
#goToTopBtn {
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: rgba($lightgray, 0.8); /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  border-radius: 10px; /* Rounded corners */
  font-size: 16px; /* Increase font size */
}

// WIGGLE BUTTON
.btn-wiggle {
  -webkit-animation: wiggle 4s 2s infinite;
  animation: wiggle 4s 2s infinite;
}

@-webkit-keyframes wiggle {
  5%,
  50% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  15% {
    transform: scale(1.05);
  }
  20% {
    transform: scale(1.05) rotate(-5deg);
  }
  25% {
    transform: scale(1.05) rotate(5deg);
  }
  30% {
    transform: scale(1.05) rotate(-3deg);
  }
  35% {
    transform: scale(1.05) rotate(2deg);
  }
  40% {
    transform: scale(1.05) rotate(0);
  }
}

@keyframes wiggle {
  5%,
  50% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  15% {
    transform: scale(1.05);
  }
  20% {
    transform: scale(1.05) rotate(-5deg);
  }
  25% {
    transform: scale(1.05) rotate(5deg);
  }
  30% {
    transform: scale(1.05) rotate(-3deg);
  }
  35% {
    transform: scale(1.05) rotate(2deg);
  }
  40% {
    transform: scale(1.05) rotate(0);
  }
}

// BLOB BUTTON
.btn-blob {
  z-index: 1;
  position: relative;
  // text-align: center;
  background-color: transparent;
  outline: none;
  border: none;
  transition: color 0.5s;
  cursor: pointer;
  // border-radius: 5px;
}
.btn-blob:before {
  content: "";
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // border: 2px solid $primary;
  // border-radius: 5px;
}
.btn-blob:after {
  content: "";
  z-index: -2;
  position: absolute;
  left: 3px;
  top: 3px;
  width: 100%;
  height: 100%;
  transition: all 0.3s 0.2s;
  // border-radius: 5px;
}
.btn-blob:hover {
  color: #ffffff;
  // border-radius: 5px;
}
.btn-blob:hover:after {
  transition: all 0.3s;
  left: 0;
  top: 0;
  // border-radius: 5px;
}
.btn-blob__inner {
  z-index: -1;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // border-radius: 5px;
  background: #ffffff;
}
.btn-blob__blobs {
  position: relative;
  display: block;
  height: 100%;
  filter: url("#goo");
}
.btn-blob__blob {
  position: absolute;
  top: 2px;
  width: 25%;
  height: 100%;
  background: $blueberry;
  border-radius: 100%;
  transform: translate3d(0, 150%, 0) scale(1.7);
  transition: transform 0.45s;
}
@supports (filter: url("#goo")) {
  .btn-blob__blob {
    transform: translate3d(0, 150%, 0) scale(1.4);
  }
}
.btn-blob__blob:nth-child(1) {
  left: 0%;
  transition-delay: 0s;
}
.btn-blob__blob:nth-child(2) {
  left: 25%;
  transition-delay: 0.08s;
}
.btn-blob__blob:nth-child(3) {
  left: 50%;
  transition-delay: 0.16s;
}
.btn-blob__blob:nth-child(4) {
  left: 75%;
  transition-delay: 0.24s;
}
.btn-blob:hover .btn-blob__blob {
  transform: translateZ(0) scale(1.7);
}
@supports (filter: url("#goo")) {
  .btn-blob:hover .btn-blob__blob {
    transform: translateZ(0) scale(1.7);
  }
}

// LOADER
#loader {
  position: fixed;
  width: 96px;
  height: 96px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba($white, 0.9);
  box-shadow: 0px 24px 64px rgba($black, 0.24);
  border-radius: 16px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-out, visibility 0s linear 0.2s;
  z-index: 1000;
}

#loader.fullscreen {
  padding: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: none;
  background-color: $white;
  border-radius: 0;
  box-shadow: none;
}

#loader.show {
  transition: opacity 0.4s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

#loader .circular {
  animation: loader-rotate 2s linear infinite;
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  display: block;
  transform: rotate(0deg);
}

#loader .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: loader-dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes loader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -136px;
  }
}

.position-loader {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid $primary; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// =================================================================
// reCaptcha

.grecaptcha-badge {
  // transform: scale(0.7);
  // @include media-breakpoint-down(md) {
  //   visibility: hidden;
  // }
  visibility: hidden;
}
